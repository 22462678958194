// Remove .menu-open on page load
exports.onRouteUpdate = ({ location, prevLocation }) => {
  document.body.classList.remove('menu-open')

  if (window._ictt) {
    window._ictt.push(['_enableSPAMode'])
    window._ictt.push(['_enableGAIntegration', { gua: !0, ga: !1 }])
    window._ictt.push(['_enableAutoDiscovery'])
    window._ictt.push(['_track'])
  }
}

// Serivce finds updates and reloads
exports.onServiceWorkerUpdateReady = () => window.location.reload(true)
