module.exports = [{
      plugin: require('/builds/cursol-front/zoopay-website-ui/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.zoopay.com"},
    },{
      plugin: require('/builds/cursol-front/zoopay-website-ui/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/cursol-front/zoopay-website-ui/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/cursol-front/zoopay-website-ui/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":true},
    },{
      plugin: require('/builds/cursol-front/zoopay-website-ui/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zoopay","short_name":"Zoopay","description":"Talk to Our Zoopay Team Today on +44 207 740 0001 or get a Free Demo. Save Money on Your Personal and Business Currency Payments.","start_url":"/","background_color":"#ffffff","theme_color":"#143d53","display":"minimal-ui","icon":"static/images/android-icon-512x512.png"},
    },{
      plugin: require('/builds/cursol-front/zoopay-website-ui/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#143d53"},
    },{
      plugin: require('/builds/cursol-front/zoopay-website-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
