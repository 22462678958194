// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-legal-js": () => import("/builds/cursol-front/zoopay-website-ui/src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-pages-index-js": () => import("/builds/cursol-front/zoopay-website-ui/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/builds/cursol-front/zoopay-website-ui/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/builds/cursol-front/zoopay-website-ui/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/builds/cursol-front/zoopay-website-ui/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-currencies-we-support-js": () => import("/builds/cursol-front/zoopay-website-ui/src/pages/currencies-we-support.js" /* webpackChunkName: "component---src-pages-currencies-we-support-js" */),
  "component---src-pages-demo-js": () => import("/builds/cursol-front/zoopay-website-ui/src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-gateway-js": () => import("/builds/cursol-front/zoopay-website-ui/src/pages/gateway.js" /* webpackChunkName: "component---src-pages-gateway-js" */),
  "component---src-pages-offices-js": () => import("/builds/cursol-front/zoopay-website-ui/src/pages/offices.js" /* webpackChunkName: "component---src-pages-offices-js" */)
}

